import * as types from '../constants/ActionTypes';

const initialState = {
    activitySite: false,
    organisationName: '',
    organisationFullName: '',
    organisationDescription: '',
    organisationCategoryId: '',
    organisationSubCategoryId: '',
    siteSections: [],
    colorScheme: 1,
    logo: '',
    favicon: '',
    personalDataDoc: '',
    legalAddress: '',
    postAddress: '',
    receptionPhone: '',
    referencePhone: '',
    ogrn: '',
    founder_name: '',
    email: '',
    vkLink: '',
    okLink: '',
    fbLink: '',
    instagramLink: '',
    telegramLink: '',
    youTubeLink: '',
    rutubeLink: '',
    yandexZenLink: '',
    adminEmail: '',
    notificationEmails: [''],
    metric: '',
};

export function settings(state, action) {
    state = state || initialState;
    switch (action.type) {
        case types.GET_SETTINGS_STARTED:
            return state;

        case types.GET_SETTINGS_SUCCESS:
            return action.info;

        case types.GET_SETTINGS_ERROR:
            return state;

        case types.SET_SETTINGS_STARTED:
            return state;

        case types.SET_SETTINGS_SUCCESS:
            return {...state, ...action.info};

        case types.SET_SETTINGS_ERROR:
            return state;

        case types.SAVE_SETTINGS:
            return state;
        default:
            return state;
    }
}
