import * as types from '../constants/ActionTypes';
import {BASE_URL} from "../constants/EnvVariables";
import Cookies from "js-cookie";
import {snakeKeys} from 'js-convert-case';
import {ajaxErrorRedirect} from "../helpers/ajaxErrorRedirect";

export function getSettings() {
    return dispatch => {
        dispatch({
            type: types.GET_SETTINGS_STARTED
        });

        window.$.ajax({
            type: "GET",
            dataType: 'json',
            url: BASE_URL + "api/sites",
            headers: {
                'Authorization': "Bearer " + Cookies.get('site_builder_token')
            },
            success: function (data, status, xhr) {
                dispatch({
                    type: types.GET_SETTINGS_SUCCESS,
                    info: data,
                });
            },

            error: function (data) {
                ajaxErrorRedirect(data);

                dispatch({
                    type: types.GET_SETTINGS_ERROR,
                    message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                });
            }
        });
    };
}


export function setSettings(payload) {
    let dataNew = Object.assign({}, payload);

    return dispatch => {
        dispatch({
            type: types.SET_SETTINGS_STARTED
        });

        if (!window.editBlocked) {
            return Promise.resolve(window.$.ajax({
                type: "PUT",
                dataType: 'json',
                url: BASE_URL + "api/sites/" + payload.id + "?_method=PUT",
                data: snakeKeys(payload),
                headers: {
                    'Authorization': "Bearer " + Cookies.get('site_builder_token')
                },
                success: function (data, status, xhr) {
                    dispatch({
                        type: types.SET_SETTINGS_SUCCESS,
                        info: dataNew,
                    });
                },

                error: function (data) {
                    ajaxErrorRedirect(data);

                    dispatch({
                        type: types.SET_SETTINGS_ERROR,
                        message: data && data.responseJSON ? data.responseJSON.message : 'Ошибка'
                    });
                }
            }));
        }

    };
}

export function clearSettings() {
    return dispatch => {
        dispatch({
            type: types.GET_SETTINGS_SUCCESS,
            info: {
                activitySite: false,
                organisationName: '',
                organisationFullName: '',
                organisationDescription: '',
                organisationCategoryId: '',
                organisationSubCategoryId: '',
                siteSections: [],
                colorScheme: 1,
                logo: '',
                favicon: '',
                personalDataDoc: '',
                legalAddress: '',
                postAddress: '',
                receptionPhone: '',
                referencePhone: '',
                ogrn: '',
                founder_name: '',
                email: '',
                vkLink: '',
                okLink: '',
                fbLink: '',
                instagramLink: '',
                telegramLink: '',
                youTubeLink: '',
                rutubeLink: '',
                yandexZenLink: '',
                adminEmail: '',
                notificationEmails: [''],
                metric: '',
            },
        });
    };
}
